export default [
  { name: "Epicentral", hash: "dd700ebefd3be42038d23d3a5bb13f70" },
  { name: "Map", hash: "88ed053ef4280c1153ad0add4ee4ae2f" },
  { name: "Events", hash: "ZXZlbnRzX3BhZ2VfYXpfbW9zdF9kZWVwbGluaw" },
  { name: "Betalok", hash: "72194fe7d6eb1600b9518f0ceab1fde8" },
  { name: "Galaxy", hash: "e03239b27e34a5f7f3bde739459dd537" },
  { name: "SkvAcademy", hash: "ce10238dbf11023c43ddf27a9e3b741e" },
  { name: "TimeHobl", hash: "3a7dbae2ea7e84704761e4aba89351a6" },
  { name: "NarodnyeSkazki", hash: "72d9de5d2c64b936e4f0eddea673b238" },
  { name: "safneloPartOne", hash: "8a8fb39220c0feb3d073ef048d7865e1" },
  { name: "safneloPartTwo", hash: "9220c0feb8a8fb33d073ef048d7865e1" },
  { name: "Forsiga", hash: "0033db14bd55bdb6430356c78161a48a" },
  { name: "Krepost", hash: "6adcc8c54f28a46f5c85c2cd4d14fe8a" },
  { name: "SeeMore", hash: "5fac779c6088c56208e4ed39226da2a4" },
  { name: "ProHBP", hash: "6c3682006734b0e048ede84f5a1051eb" },
  { name: "ProHBP2", hash: "876ff68db39d3f237eff328f4c4ec335" },
  { name: "Ultomiris", hash: "43b3bd25d07ee8bdd8cb095b2cc2c8ed" },
  { name: "EnhertuESMO", hash: "1f61f3f9a5885738a40d6358a7367550" },
  { name: "EventOC", hash: "0a0129952b1615c67da3e3f9a7b24fb1" },
  { name: "EventRMG", hash: "b2af30fe22a59ac1e6dfb16cb459ce25" },
  { name: "EventOC", hash: "0a0129952b1615c67da3e3f9a7b24fb1" },
  { name: "EventHSN", hash: "a5eb415107f777c5caf081a6b9c0dcf8" },
  { name: "Epitelium", hash: "589fab7cd12b743cbc766e6a7d950fd4" },
  { name: "EpiteliumGame", hash: "589fab7cd12b743cbc766e6a7d950fd4" },
  { name: "EventRMG", hash: "b2af30fe22a59ac1e6dfb16cb459ce25" },
  { name: "EpiteliumQuiz", hash: "589fab7cd12b743cbc766e6a7d950fd4" },
  { name: "NewYearVideo", hash: "5bbc6b13f0417e8b117f35f06e096859" },
  { name: "SkvQuestions", hash: "125a075567e2d2b16bad6258a1b9b750" },
  { name: "HER2", hash: "76695599902b840f8b091739827a36b8" },
  { name: "HER2LOW", hash: "17c31c15d516c6feedcd74ab305c2522" },
  { name: "EducationDetail", hash: "4fe032cd8d505abf3748ae8000f5aa87" },
  { name: "SevereAsthma", hash: "ca6d38567f639ada8c398babb1fdc176" },
  { name: "TimeSPB", hash: "cf75ec93ee22de56e9e21fda48188d3e" },
  { name: "TimeNSK", hash: "7c464645572ad9a7aca64d854b280d8a" },
  { name: "10years", hash: "74dca3f5d0cca84079f051f98fc91e2f" },
  { name: "RegionsForward", hash: "b7d33756cb90684ba0a6f21e8630969a" },
  { name: "SovremennayaNevrologiya", hash: "8318e29125c31a725a9c454f7cb66c48" },
  { name: "PodPrikrutiem", hash: "814d0939f484fb970547a4af8b79ece9" },
  { name: "BetalockLand", hash: "13a1dea8138f04438571e2f7c3d65487" },
  { name: "EnhertuEvent", hash: "7c9c7077ba7fe2f51e3a1b721e38a483" },
  { name: "BannersPage", hash: "2fa2617eac56831b338df26cf25d867a" },
  { name: "LiverWebinar", hash: "2f5a7bcdb4a675db37d7f6c996e7d911" },
  { name: "GalaxyStar", hash: "32bcb7ae3e578607fa9609a163193752" },
  { name: "LiveWithBlock", hash: "f192980f4983e76a5f83913594aefe99" },
  { name: "IntensiveTherapy", hash: "979638bf85dc8bd4083df026acabd80a" },
  { name: "HeartSymposium", hash: "10470ca93261c6c6c9fc3449b49892f1" },
  { name: "StomachCancer", hash: "a014ad6c8a085511cac078a9e6076684" },
  { name: "Prorak", hash: "083d674510317b0bea36d90a1704c705" },
  { name: "Prorak2", hash: "9548cd412ee512545bad11b2f310c3e1" },
  { name: "Gastrointestinal", hash: "ef024b5af1d18f9c4757442caeea693d" },
  { name: "AnalyzeEvent", hash: "3c149b192fc67903fa160a573e81dc0c" },
  { name: "EnhertuTime", hash: "ae98705040fbcaf177701b84d27f3bcc" },
  { name: "AkdainaEvent", hash: "9ea9c95ccc5c3869d5dfa74b1a0ff1a9" },
  { name: "AutumnSchool", hash: "b4b67b7b16447bf0fc9227c9e137db9d" },
  { name: "TbaEvent", hash: "ac7fdfd8a677418c2266959ea0670679" },
  { name: "MetastaticStomachCancer", hash: "b4dcb6d297611cc36e42ecbf8267786d" },
  { name: "AgnosticBiomarker", hash: "b1c25cbf1a7a1b8f8539bb76d7e73667" },
  { name: "AgnosticBiomarker2", hash: "1eaf09c17fe065a44dffc74f867d8e33" },
  { name: "GeniusHeart", hash: "0702b4c601cc0cdb4b2ec70acaec668c" },
  { name: "Shine", hash: "3e0d11930ea51916a99f23f29a9f6492" },
  { name: "Shine-test", hash: "3e0d11930ea51916a99f23f29a9f6492" },
  { name: "IBSReal", hash: "c681107fb347d4d6c737eb27ad4fb44a" },
  { name: "NonlipidEffects", hash: "3c11221c7c95c4c2cc26ead996000d95" },
  { name: "InteractiveClinicalCase", hash: "ad4d07e3b2708ade91ee4d28825f219d" },
  { name: "NewOpportunities", hash: "118e8e7853bfb44f0e11ea8643cbc151" },
  { name: "IBSRisk", hash: "b9f72a90bfbbf7b086c734a946f207c0" },
  { name: "Her2AndMrj", hash: "5f52cef9c33e3e3e73bafc6096d31166" },
  { name: "SD2", hash: "0ea0ee917267406799e874c88954ba97" },
  { name: "Interaction", hash: "61ee5a22cd5f14ed4e48eb5611031e74" },
  { name: "Wolman", hash: "74727df03b4c78cf907efe51b70b3954" },
  { name: "CallCenter", hash: "de20b7e4688e9342e0102e204fbb683b" },
  { name: "SkvEvent", hash: "ad4d07e3b2708ade91ee4d2885453432h" },
  { name: "DayOfRareDiseases", hash: "2cac79249aa5436a3dcb29fd7cb6ad0f" },
  { name: "NexiumWebinar", hash: "ad4d07e3b2708ade91tret3451da" },
  { name: "LocomotivWebinar", hash: "ad4d07e3b2708ade91ee4d288545376g" },
  { name: "MythsWebinar", hash: "ad4d07e3b27fd34612gva43" },
  { name: "BestPractice", hash: "c2a62ad8e2944e1e180ac19820cef785" },
]
