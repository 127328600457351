import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import axios from "axios";
import deeplinks from "./staticDeeplinks";

function load(component) {
  return () => import(`@/views/${component}.vue`);
}

Vue.use(VueRouter);
let routes = [];
if (process.env.VUE_APP_CLOSED && !document.cookie.includes("access")) {
  routes = [
    {
      path: "*",
      name: "Plug",
      component: load("Plug"),
      meta: {
        needAuth: false,
        layout: "empty",
      },
    },
  ];
} else {
  routes = [
    {
      path: "/",
      name: "MainPage",
      component: load("MainPage"),
      meta: {
        layout: "main",
      },
    },
    {
      path: "/survey-:id",
      name: "Survey",
      component: load("Survey"),
      meta: {
        layout: "main",
      },
    },
    {
      path: "/therapeutic-areas/:slug",
      name: "NosologyDetail",
      component: load("NosologyDetail"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/therapeutic-areas/:slug/:disease",
      name: "Disease",
      component: load("SubgroupLayout"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    // {
    //   path: "/therapeutic-areas/:slug/:subgroup",
    //   name: "Subgroup",
    //   component: load("SubgroupLayout"),
    //   meta: {
    //     layout: "main",
    //     auth: true,
    //   },
    // },
    {
      path: "/therapeutic-areas/:slug/:subgroup/:disease",
      name: "SupDisease",
      component: load("Disease"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    // {
    //   path: "/register",
    //   name: "Register",
    //   component: load("auth/Register"),
    //   meta: {
    //     layout: "auth",
    //   },
    // },
    // {
    //   path: "/tg-register",
    //   name: "TgRegister",
    //   component: load("auth/Register"),
    //   meta: {
    //     layout: "auth",
    //   },
    // },
    // {
    //   path: "/login",
    //   name: "Login",
    //   component: load("auth/Login"),
    //   meta: {
    //     layout: "auth",
    //   },
    // },
    // {
    //   path: '/user-update',
    //   name: 'UserUpdate',
    //   component: load('auth/UserUpdate'),
    //   meta: {
    //     layout: 'auth'
    //   }
    // },
    {
      path: "/library",
      name: "Library",
      component: load("library/Library"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/library/videomaterials/",
      name: "VideoMaterials",
      component: load("library/VideoMaterials"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/library/podcasts/",
      name: "Podcasts",
      component: load("library/Podcasts"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/library/textmaterials",
      name: "TextMaterials",
      component: load("library/TextMaterials"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/library/textmaterials/:slug",
      name: "TextDetail",
      component: load("library/TextDetail"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/library/videomaterials/:slug",
      name: "VideoDetail",
      component: load("library/VideoDetail"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/library/podcasts/:slug",
      name: "PodcastDetail",
      component: load("library/PodcastDetail"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/cabinet",
      name: "Cabinet",
      component: load("Cabinet"),
      meta: {
        layout: "cabinet",
        auth: true,
      },
    },
    {
      path: "/materials",
      name: "Materials",
      component: load("cabinet/Materials"),
      meta: {
        layout: "cabinet",
        auth: true,
      },
    },
    {
      path: "/recommended-events",
      name: "RecommendedEvents",
      component: load("cabinet/Events"),
      meta: {
        layout: "cabinet",
        auth: true,
      },
    },
    {
      path: "/recommended-events/:type(active|passed)",
      name: "RecommendedEventsDetail",
      component: load("cabinet/EventsDetail"),
      meta: {
        layout: "cabinet",
        auth: true,
      },
    },
    {
      path: "/materials/settings",
      name: "MaterialsSettings",
      component: load("cabinet/MaterialsSettings"),
      meta: {
        layout: "cabinet",
        auth: true,
      },
    },
    {
      path: "/recommended-events/settings",
      name: "RecommendedEventsSettings",
      component: load("cabinet/MaterialsSettings"),
      meta: {
        layout: "cabinet",
        auth: true,
      },
    },
    {
      path: "/materials/:type(text|podcasts|video|diseases|medications|projects)",
      name: "MaterialsDetail",
      component: load("cabinet/MaterialsDetail"),
      meta: {
        layout: "cabinet",
        auth: true,
      },
    },
    {
      path: "/favorites",
      name: "Favorites",
      component: load("cabinet/Favorites"),
      meta: {
        layout: "cabinet",
        auth: true,
      },
    },
    {
      path: "/history",
      name: "History",
      component: load("cabinet/History"),
      meta: {
        layout: "cabinet",
        auth: true,
      },
    },
    {
      path: "/watch-later",
      name: "WatchLater",
      component: load("cabinet/WatchLater"),
      meta: {
        layout: "cabinet",
        auth: true,
      },
    },
    {
      path: "/restore-password",
      name: "RestorePassword",
      component: load("auth/RestorePassword"),
      meta: {
        layout: "auth",
      },
    },
    {
      path: "/therapeutic-areas",
      name: "Nosology",
      component: load("Nosology"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/medications",
      name: "Medications",
      component: load("Medications"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      name: "BetalockLand",
      path: "/medication/betalok",
      component: load("anotherPages/Betalock"),
      meta: {
        layout: "betalock",
        auth: true,
      },
    },
    {
      path: "/medication/:medication",
      name: "DrugsTemplates",
      component: load("DrugsTemplates"),
      meta: {
        layout: "main",
        noStickyHead: true,
        auth: true,
      },
    },
    {
      path: "/events",
      name: "Events",
      component: load("events/Events"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/event/:id",
      name: "EventDetail",
      component: load("events/EventDetail"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/contacts",
      name: "ContactUs",
      component: load("ContactUs"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/report-adverse-event",
      name: "ReportAdverseEvent",
      component: load("ReportAdverseEvent"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/complaint-to-the-drug",
      name: "ComplaintDrug",
      component: load("ComplaintDrug"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/report-a-drug-claim",
      name: "ReportDrugClaim",
      component: load("ReportDrugClaim"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/medical-info",
      name: "MedicalInfo",
      component: load("MedicalInfo"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/betalok",
      name: "Betalok",
      component: load("Betalok"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/all-rights-reserved",
      name: "AllRightsReserved",
      component: load("AllRightsReserved"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/oauth",
      name: "OAuth",
      component: load("auth/OAuth"),
      meta: {
        layout: "empty",
      },
    },
    {
      path: "/az-sso",
      name: "AzSsoAuth",
      component: load("auth/AzSsoAuth"),
      meta: {
        layout: "empty",
        no_session_request: true
      },
    },
    {
      path: "/map",
      name: "Map",
      component: load("Map"),
      meta: {
        layout: "empty",
        auth: true,
      },
    },
    // {
    //   path: "/email-safnelo",
    //   name: "EmailSafnelo",
    //   component: load("EmailSafnelo"),
    //   meta: {
    //     layout: "main",
    //     auth: false,
    //   },
    // },
    {
      path: "/skv-academy",
      name: "SkvAcademy",
      component: load("anotherPages/SkvAcademy"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    // {
    //   path: "/faq-lald",
    //   name: "Lald",
    //   component: load("anotherPages/Lald"),
    //   meta: {
    //     layout: "main",
    //     auth: false,
    //   },
    // },
    // {
    //   path: "/faq-hpp",
    //   name: "Gff",
    //   component: load("anotherPages/Gff"),
    //   meta: {
    //     layout: "main",
    //     auth: false,
    //   },
    // },
    {
      path: "/lald",
      name: "LaldClosed",
      component: load("anotherPages/Lald"),
      meta: {
        layout: "main",
        auth: false,
        closed: true,
      },
    },
    {
      path: "/hpp",
      name: "GffClosed",
      component: load("anotherPages/Gff"),
      meta: {
        layout: "main",
        auth: false,
        closed: true,
      },
    },
    {
      path: "/hpp-pdf",
      name: "GffPdf",
      component: load("anotherPages/Lald-pdf"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/lald-pdf",
      name: "laldPdf",
      component: load("anotherPages/Gff-pdf"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    // {
    //   path: "/bremya_skv",
    //   name: "Skv",
    //   component: load("anotherPages/Skv"),
    //   meta: {
    //     layout: "main",
    //     auth: true,
    //   },
    // },
    {
      path: "/galaxy",
      name: "Galaxy",
      component: load("anotherPages/Galaxy"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/galaxy/:frame",
      name: "GalaxyFrame",
      component: load("anotherPages/GalaxyFrame"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/med-info",
      name: "MedInfo",
      component: load("MedInfo"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/vremya-hobl",
      name: "TimeHobl",
      component: load("anotherPages/TimeHobl"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/vremya-hobl/:frame",
      name: "TimeHoblFrame",
      component: load("anotherPages/TimeHoblFrame"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    // {
    //   path: '/narodnye-antiagregantnye-skazki',
    //   name: 'NarodnyeSkazki',
    //   component: load('anotherPages/NarodnyeSkazki'),
    //   meta: {
    //     layout: 'main',
    //     auth: true
    //   }
    // },
    {
      path: "/safnelo-p1",
      name: "safneloPartOne",
      component: load("anotherPages/Safnelo-1"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/safnelo-p2",
      name: "safneloPartTwo",
      component: load("anotherPages/Safnelo-2"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/tg",
      name: "ToTgChanel",
      component: load("ToTgChanel"),
      meta: {
        layout: "empty",
        auth: true,
      },
    },
    {
      path: "/forsiga",
      name: "Forsiga",
      component: load("anotherPages/Forsiga"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/krepost",
      name: "Krepost",
      component: load("anotherPages/Krepost"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/komplement-forum",
      name: "Ultomiris",
      component: load("anotherPages/Ultomiris"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/bolshe",
      name: "SeeMore",
      component: load("anotherPages/SeeMore"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/epitelium",
      name: "Epitelium",
      component: load("anotherPages/Epitelium"),
      meta: {
        layout: "main_black",
        auth: false,
      },
    },
    {
      path: "/epitelium/game",
      name: "EpiteliumGame",
      component: load("anotherPages/EpiteliumGame"),
      meta: {
        layout: "main_black",
        auth: true,
      },
    },
    {
      path: "/epitelium/quiz",
      name: "EpiteliumQuiz",
      component: load("anotherPages/EpiteliumQuiz"),
      meta: {
        layout: "main_black",
        auth: true,
      },
    },
    {
      path: "/skv_index_calculator",
      name: "SkvQuestions",
      component: load("anotherPages/SkvQuestions"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/enhertu",
      name: "EnhertuESMO",
      component: load("anotherPages/EnhertuESMO"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/event-rmg",
      name: "EventRMG",
      component: load("anotherPages/EventRMG"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/event-oc",
      name: "EventOC",
      component: load("anotherPages/EventOC"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/epicentral",
      name: "Epicentral",
      component: load("epicentral/EpicentralNew"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/epicentral/:slug",
      name: "EpicentralDetail",
      component: load("epicentral/EpicentralDetail"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      name: "EventHSN",
      path: "/event-hsn",
      component: load("anotherPages/EventHSN"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/nf-1",
      name: "NF-1",
      component: load("anotherPages/NF1"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/new-year-video",
      name: "NewYearVideo",
      component: load("anotherPages/NewYearVideo"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      name: "Projects",
      path: "/projects",
      component: load("projects/Projects"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      name: "ArchiveEvents",
      path: "/archive-events",
      component: load("archiveEvents/ArchiveEvents"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      name: "HER2",
      path: "/medication/enhertu/her2",
      component: load("medications/enhertu/her2/HER2"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      name: "HER2LOW",
      path: "/medication/enhertu/her2low",
      component: load("medications/enhertu/her2low/HER2"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      name: "Education",
      path: "/trainings",
      alias: ['/education'],
      component: load("education/Education"),
      meta: {
        layout: "education",
        auth: true,
      },
    },
    {
      name: "EducationDetail",
      path: "/trainings/:slug",
      alias: ['/education/:slug'],
      component: load("education/EducationDetail"),
      meta: {
        layout: "education",
        auth: true,
      },
    },
    {
      name: "Surveys",
      path: "/surveys",
      component: load("Surveys"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      name: "SevereAsthma",
      path: "/severeasthmanewera",
      component: load("anotherPages/SevereAsthma"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      name: "TimeSPB",
      path: "/vremyaspb",
      component: load("anotherPages/TimeSPB"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      name: "TimeNSK",
      path: "/vremyansk",
      component: load("anotherPages/TimeNSK"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      name: "TimeUFA",
      path: "/vremyaufa",
      component: load("anotherPages/TimeUFA"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/prohbp",
      name: "ProHBP",
      component: load("anotherPages/ProHBP"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/prohbp-event",
      name: "ProHBP2",
      component: load("anotherPages/ProHBP2"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      path: "/10years",
      name: "10Years",
      component: load("anotherPages/10years"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/regiony-vpered",
      name: "RegionsForward",
      component: load("anotherPages/RegionsForward"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/success",
      name: "Unsubscribe",
      component: load("Unsubscribe"),
      meta: {
        layout: "empty",
        auth: false,
      },
    },
    {
      path: "/pod_prikrutiem",
      name: "PodPrikrutiem",
      component: load("anotherPages/PodPrikrutiem"),
      meta: {
        layout: "main_black",
        auth: true,
      },
    },
    // {
    //   path: "/ne-po-sekrety",
    //   name: "NePoSekrety",
    //   component: load("anotherPages/NePoSekrety"),
    //   meta: {
    //     layout: "main",
    //     auth: false,
    //   },
    // },
    {
      path: "/tezspire",
      name: "Tezspire",
      component: load("anotherPages/Tezspire"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      name: "TimeIrkutsk",
      path: "/vremyairkutsk",
      component: load("anotherPages/TimeIrkutsk"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      name: "SovremennayaNevrologiya",
      path: "/sovremennaya-nevrologiya",
      component: load("anotherPages/SovremennayaNevrologiya"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      name: "BannersPage",
      path: "/neurology",
      component: load("anotherPages/BannersPage"),
      meta: {
        layout: "special",
        auth: true,
      },
    },
    {
      name: "NevroPromo",
      path: "/attr",
      component: load("anotherPages/NevroPromo"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",
          }
        ]
      },
    },
    {
      name: "SD2",
      path: "/monitoring",
      component: load("anotherPages/SD2"),
      meta: {
        layout: "sd2",
        auth: true,
      },
    },
    {
      name: "EnhertuEvent",
      path: "/enhertu-event",
      component: load("anotherPages/EnhertuEvent"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    // {
    //   name: "ZSONMEvent",
    //   path: "/komplement-forum-v-fokuse-zsonm-i-miasteniya",
    //   component: load("anotherPages/ZSONMEvent"),
    //   meta: {
    //     layout: "main",
    //     auth: false,
    //   },
    // },
    {
      name: "LiverWebinar",
      path: "/liver-webinar",
      component: load("anotherPages/LiverWebinar"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      name: "GalaxyStar",
      path: "/galaxy-star",
      component: load("anotherPages/GalaxyStar"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    // {
    //   name: "HeartSymposium",
    //   path: "/heart-symposium",
    //   component: load("anotherPages/HeartSymposium"),
    //   meta: {
    //     layout: "main",
    //     auth: true,
    //     pageVerify: [
    //       {
    //         number: "            ",

    //       }
    //     ]
    //   },
    // },
    {
      name: "LiveWithBlock",
      path: "/continue-life",
      component: load("anotherPages/LiveWithBlock"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      name: "IntensiveTherapy",
      path: "/intensive-therapy",
      component: load("anotherPages/IntensiveTherapy"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    // {
    //   name: "StarGalaxy",
    //   path: "/cardiovascular-diseases",
    //   component: load("anotherPages/StarGalaxy"),
    //   meta: {
    //     layout: "main",
    //     auth: true,
    //     pageVerify: [
    //       {
    //         number: "            ",

    //       }
    //     ]
    //   },
    // },
    {
      name: "StomachCancer",
      path: "/stomach-cancer-event",
      component: load("anotherPages/StomachCancer"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      name: "MetastaticStomachCancer",
      path: "/metastatic-stomach-cancer",
      component: load("anotherPages/MetastaticStomachCancer"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      name: "Prorak",
      path: "/prorak",
      component: load("anotherPages/Prorak"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "TAG-RU-22837",

          }
        ]
      },
    },
    {
      name: "Prorak2",
      path: "/prorak2",
      component: load("anotherPages/Prorak2"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      name: "Gastrointestinal",
      path: "/gastrointestinal",
      component: load("anotherPages/Gastrointestinal"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      name: "AnalyzeEvent",
      path: "/analysis-completed-studies",
      component: load("anotherPages/AnalyzeEvent"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      name: "EnhertuTime",
      path: "/enhertu-time",
      component: load("anotherPages/EnhertuTime"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      name: "AkdainaEvent",
      path: "/akdaina-event",
      component: load("anotherPages/AkdainaEvent"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      name: "AutumnSchool",
      path: "/autumn-school",
      component: load("anotherPages/AutumnSchool"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      name: "AgnosticBiomarker",
      path: "/agnostic-biomarker",
      component: load("anotherPages/AgnosticBiomarker"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      name: "AgnosticBiomarker2",
      path: "/agnostic-biomarker-2",
      component: load("anotherPages/AgnosticBiomarker2"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      name: "GeniusHeart",
      path: "/genius-of-the-heart",
      component: load("anotherPages/GeniusHeart"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      name: "TbaEvent",
      path: "/tba-event",
      component: load("anotherPages/TbaEvent"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      name: "Shine",
      path: "/shine",
      component: load("anotherPages/Shine"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      name: "ShineTest",
      path: "/shine-test",
      component: load("anotherPages/Shine"),
      meta: {
        layout: "main",
        auth: true,
        slug: 'shine-test',
        date: "2024-11-07T10:00:17+03:00",
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      name: "IBSReal",
      path: "/ibs-real",
      component: load("anotherPages/IBSReal"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      name: "NonlipidEffects",
      path: "/non-lipid-effects",
      component: load("anotherPages/NonlipidEffects"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      name: "InteractiveClinicalCase",
      path: "/interactive-clinical-case",
      component: load("InteractiveClinicalCase"),
      meta: {
        layout: "icc",
        auth: true,
        noStickyHead: true
      },
    },
    {
      name: "NewOpportunities",
      path: "/new-opportunities",
      component: load("anotherPages/NewOpportunities"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      name: "Her2AndMrj",
      path: "/her2-and-mrj",
      component: load("anotherPages/Her2andMrj"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      name: "IBSRisk",
      path: "/ibs-risk",
      component: load("anotherPages/IBSRisk"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      name: "Policy",
      path: "/register",
      component: load("Policy"),
      meta: {
        layout: "empty",
        auth: false,
      },
    },
    {
      name: "Mspk",
      path: "/mspk",
      component: load("anotherPages/MSPK"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      name: "Interaction",
      path: "/interaction",
      component: load("Interaction"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      name: "Wolman",
      path: "/wolmans-disease",
      component: load("anotherPages/Wolman"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      name: "CallCenter",
      path: "/call-center",
      component: load("anotherPages/CallCenter"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      name: "SkvEvent",
      path: "/questions-skv",
      component: load("anotherPages/SkvEvent"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    // {
    //   name: "DayOfRareDiseases",
    //   path: "/day-of-rare-diseases",
    //   component: load("anotherPages/DayOfRareDiseases"),
    //   meta: {
    //     layout: "main",
    //     auth: true,
    //     pageVerify: [
    //       {
    //         number: "            ",

    //       }
    //     ]
    //   },
    // },
    {
      name: "SkvEvent",
      path: "/questions-skv",
      component: load("anotherPages/SkvEvent"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      name: "NexiumWebinar",
      path: "/nexium-webinar",
      component: load("anotherPages/NexiumWebinar"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      name: "LocomotivWebinar",
      path: "/locomotive-webinar",
      component: load("anotherPages/LocomotivWebinar"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      name: "MythsWebinar",
      path: "/myths-webinar",
      component: load("anotherPages/MythsWebinar"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      name: "BestPractice",
      path: "/best-practice",
      component: load("anotherPages/BestPractice"),
      meta: {
        layout: "main",
        auth: true,
        pageVerify: [
          {
            number: "            ",

          }
        ]
      },
    },
    {
      path: "*",
      name: "404",
      component: load("404"),
      meta: {
        layout: "main",
      },
    },
  ];
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    document.querySelector("html").style.scrollBehavior = 'auto'
    window.onload = async () => {
      const header = document.querySelector('.header')
      const headerRect = header.getBoundingClientRect()
      if (to.hash) {
        const findEl = async (hash, x = 0) => {
          return (
            document.querySelector(hash) ||
            new Promise(resolve => {
              if (x > 50) {

                return resolve(document.querySelector("#app"));
              }
              setTimeout(() => {

                resolve(findEl(hash, ++x || 1));
              }, 100);
            })
          );
        };

        let el = await findEl(to.hash);
        window.scrollTo(0, el.getBoundingClientRect().top - headerRect.height - 30);
        return
      }
    }

    return { x: 0, y: 0 }
  },
  routes,
});

router.beforeEach(async (to, from, next) => {
  const cookies = document.cookie.split('; ').reduce((prev, current) => {
    const [name, ...value] = current.split('=');
    prev[name] = value.join('=');
    return prev;
  }, {});
  if (cookies && cookies.jwt) {
    localStorage.setItem('refresh', cookies.jwt)
  }

  const _to = to.path.replace(/\/$/, "");
  //const _from = from.fullPath.replace(/\/$/, "");
  //const _current = window.location.pathname.replace(/\/$/, "");
  const flag = _to !== "" && to.path && to.path.match(/\/$/);
  if (flag) {
    let _to2 = "";
    for (let i = 1; i < to.path.split(/\//).length - 1; i++) {
      _to2 += "/" + to.path.split(/\//)[i];
    }
    next({ ...to, path: _to2 });
  };
  if (to.name === "Cabinet" && to.query && to.query.access_token) {
    localStorage.setItem("token", to.query.access_token);
    axios.defaults.headers.common.Authorization =
      "Bearer " + to.query.access_token;
    if (await store.dispatch("getSession")) {
      let query = { ...to.query };
      delete query.access_token;
      return next({ ...to, query: { ...query } });
    } else {
      localStorage.removeItem("token");
      axios.defaults.headers.common.Authorization = null;
      if (to.query.next_url) {
        let url;
        try {
          url = new URL(to.query.next_url || to.query.source);
        } catch {
          url = new URL("https://" + (to.query.next_url || to.query.source));
        }
        window.open(url, "_self");
      } else {
        next({
          name: "MainPage",
        });
      }
    }
  }
  if (!from || !from.name) {
    if (!to.matched.some((record) => record.meta.no_session_request)) {
      await store.dispatch("getSession");
    }
  }
  if (to.name == "MedInfo") {
    const approved = [
      "anna.rezunkova@astrazeneca.com",
      "tamara.archakova@astrazeneca.com",
      "yuri.nivin@astrazeneca.com",
      "dmitrii.tikhonov@astrazeneca.com",
      "natia.kalmychkova@astrazeneca.com"
    ]
    if (store?.state?.user && store?.state?.user?.user?.email && store?.state?.user?.user?.email.toLowerCase().includes("@astrazeneca.com")) {
      if (!approved.includes(store?.state?.user?.user?.email.toLowerCase())) {
        return next("/")
      }
    }
  }
  const isAuth = store.state.user.isAuth

  if (isAuth && isAuth !== "undefined") {
    if (
      to.name === "Login" ||
      to.name === "Register" ||
      to.name === "TgRegister"
    ) {
      if (to.name === "TgRegister") {
        next({
          name: "ToTgChanel",
          params: { tg: true },
        });
      } else {
        next({
          name: "MainPage",
        });
      }
    }
  }
  if (to.matched.some((record) => record.meta.auth)) {
    let fasttrack = to.query.fasttrack ? `&fasttrack=${to.query.fasttrack}` : ''
    const ssoLinks = {
      registration: `${process.env.VUE_APP_SSO_HOST}signup?source=${encodeURIComponent(window.location.href)}&next_url=${window.location.origin}/az-sso&back_url=${encodeURIComponent(window.location.origin + to.fullPath)}${fasttrack}`,
      login: `${process.env.VUE_APP_SSO_HOST}signin?source=${encodeURIComponent(window.location.href)}&next_url=${window.location.origin}/az-sso&back_url=${encodeURIComponent(window.location.origin + to.fullPath)}${fasttrack}`
    }
    if (Object.keys(to.query) && to.query.access) {
      const deeplink = deeplinks.find((el) => el.name == to.name)
      if (deeplink) {
        if (deeplink.hash === to.query.access) {
          next();
        } else {
          if (isAuth) {
            next();
          } else {
            next(false);
            window.open(ssoLinks.login, "_self");
          }
        }
      }
    } else if (!isAuth) {
      window.open(ssoLinks.login, '_self')
      return next(false)
    }
  }
  next();
});

export default router;
