import axios from "axios";
import Vue from 'vue'
import Vuex from 'vuex'
import store from '@/store'

axios.defaults.baseURL = `${process.env.VUE_APP_BACKEND_HOST}/`;
axios.defaults.withCredentials = true;
axios.defaults.headers.common.Authorization = localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : null;
const ssoLinks = {
  registration: `${process.env.VUE_APP_SSO_HOST}signup?source=${encodeURIComponent(window.location.href)}&next_url=${window.location.origin}/az-sso&back_url=${encodeURIComponent(window.location.href)}`,
  login: `${process.env.VUE_APP_SSO_HOST}signin?source=${encodeURIComponent(window.location.href)}&next_url=${window.location.origin}/az-sso&back_url=${encodeURIComponent(window.location.href)}`
}

const cookies = document.cookie.split('; ').reduce((prev, current) => {
  const [name, ...value] = current.split('=');
  prev[name] = value.join('=');
  return prev;
}, {});



export default {
  async install() {
    Vue.prototype.$axios = axios
    Vuex.Store.prototype.$axios = axios

    function request(request) {
      if (request.params) {
        request.systemParams = new URLSearchParams(request.params.toString())
      }

      if (request.headers.common.Authorization) {
        if (request.params) {
          request.params.delete('access')
        }
      }


      const controller = new AbortController();
      store.dispatch("addRequest", {
        ...request,
        controller: controller
      });
      return {
        ...request,
        signal: controller.signal,
      };
    }

    function requestError() {
      store.commit("requestFailed");
    }
    axios.interceptors.request.use(request, requestError);
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        if (error.code != "ERR_CANCELED") {
          console.error(error)
        }
        if (
          error &&
          error.response &&
          error.response.status === 401 &&
          !originalRequest._retry &&
          (!error.config.url.includes("refresh"))
        ) {
          if (localStorage.getItem("refresh")) {
            originalRequest._retry = true;
            const refresh = cookies.jwt || localStorage.getItem("refresh")
            return await axios({
              url: `${process.env.VUE_APP_SSO_HOST}api/token/refresh/`,
              method: "POST",
              headers: {
                Authorization: null
              },
              data: {
                refresh: refresh,
              },
            }).then(async (res) => {
              const result = res.data;
              return await axios({
                method: "POST",
                url: "/api/auth/sso-login",
                headers: {
                  Authorization: null
                },
                data: {
                  token: result.access,
                  refresh: result.refresh
                },
              })
                .then(async (response) => {
                  const result = response.data;
                  localStorage.setItem("token", result.access_token);
                  localStorage.setItem("refresh", result.refresh);
                  axios.defaults.headers.common.Authorization =
                    "Bearer " + result.access_token;
                  originalRequest.headers.Authorization =
                    "Bearer " + result.access_token;
                  return Promise.resolve(
                    await axios.request(originalRequest)
                  );
                })
                .catch(async (e) => {

                  delete axios.defaults.headers.common.Authorization;
                  delete originalRequest.headers.Authorization;
                  localStorage.removeItem("token");
                  localStorage.removeItem("refresh");

                  if (window.location.search.includes('access')) {
                    if (!(originalRequest.params && originalRequest.params.get('access'))) {
                      if (originalRequest.systemParams && originalRequest.systemParams.get('access')) {
                        originalRequest.params.append('access', originalRequest.systemParams.get('access'))
                      }

                      return Promise.resolve(
                        await axios.request(originalRequest)
                      );
                    }


                  }

                  return Promise.reject(error);
                })
            }).catch(async (e) => {
              delete axios.defaults.headers.common.Authorization;
              delete originalRequest.headers.Authorization;
              localStorage.removeItem("token");
              localStorage.removeItem("refresh");

              if (window.location.search.includes('access')) {
                if (!(originalRequest.params && originalRequest.params.get('access'))) {
                  if (originalRequest.systemParams && originalRequest.systemParams.get('access')) {
                    originalRequest.params.append('access', originalRequest.systemParams.get('access'))
                  }

                  return Promise.resolve(
                    await axios.request(originalRequest)
                  );
                }
              }

              return Promise.reject(error);
            })
          } else {
            localStorage.removeItem("token");
            delete axios.defaults.headers.common.Authorization;
            delete originalRequest.headers.Authorization;
            originalRequest._retry = true;
            if (window.location.search.includes('access')) {
              if (originalRequest.params && originalRequest.params.get('access')) {

              } else {
                return Promise.resolve(
                  await axios.request(originalRequest)
                );
              }
            }

            return Promise.reject(error);
          }
        } else if (
          error &&
          error.response &&
          error.response.status === 401 &&
          originalRequest._retry
        ) {
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
        }
        return Promise.reject(error);
      }
    );
  }
};