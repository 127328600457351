export default {
  state: {
    user: {},
    iframeAccess: {},
    favoritesList: [],
    watchLater: [],
    history: [],
    recommendations: {},
    recommendEvents: {},
    notify: [],
    specialitiesList: [],
    specialityDetail: {},
    isAuth: false
  },
  getters: {
    user: (s) => s.user,
    IframeAccess: (s) => s.iframeAccess,
    favoritesList: (s) => s.favoritesList,
    watchLater: (s) => s.watchLater,
    history: (s) => s.history,
    notify: (s) => s.notify,
    recommendations: (s) => s.recommendations,
    recommendEvents: (s) => s.recommendEvents,
    specialitiesList: s => s.specialitiesList,
    specialityDetail: s => s.specialityDetail,
  },
  mutations: {
    updateUser(state, user) {
      state.user = user;
      state.isAuth = true
    },
    updateIframeAccess(state, link) {
      state.iframeAccess = { ...state.iframeAccess, ...link };
    },
    updateFavoritesList(state, list) {
      state.favoritesList = list;
    },
    updateWatchLater(state, list) {
      state.watchLater = list;
    },
    updateHistory(state, list) {
      state.history = list;
    },
    updateNotify(state, list) {
      state.notify = list;
    },
    updateRecommendations(state, list) {
      state.recommendations = list;
    },
    updateRecommendEvents(state, list) {
      state.recommendEvents = list;
    },
    updateSpecialitiesList(state, specialitiesList) {
      state.specialitiesList = specialitiesList.map((el) => ({ label: el.title, value: el.id }))
    },
    updateSpecialityDetail(state, specialityDetail) {
      state.specialityDetail = specialityDetail
    },
  },
  actions: {
    getSession(ctx) {
      return this.$axios({
        method: "GET",
        url: "/api/auth/session",
        
      })
        .then((response) => {
          const result = response.data;
          ctx.commit("updateUser", result);
          if (typeof ym !== 'undefined') {
            ym(91468266, 'userParams', {
              'UserID': this.helpers.b64EncodeUnicode(result.email),
              'IPAdress': this.helpers.b64EncodeUnicode(result.ip_address),
              'Areas': result.diseases.map((el) => el.id),
              'MDMID': result.mdm_id
            });
          }
          return true;
        })
        .catch((error) => {
          if (error && error.request && error.request.status !== 401) {
            if (error.request.status === 403 && error.response.data.error.includes("ban")) {
              ctx.commit("updateUser", 'ban');
              // ctx.dispatch("fetchAlerts", {
              //   alerts: "Вы несколько раз ввели неверный пароль. Доступ к порталу временно заблокирован. Пожалуйста, повторите попытку через 15 минут",
              //   type: "error",
              // });
            } else {
              ctx.dispatch("fetchAlerts", {
                alerts: "Произошла ошибка",
                type: "error",
              });
            }

          }
          return false;
        });
    },
    getUserInfo(ctx, id) {
      return this.$axios({
        method: "GET",
        url: `/api/user/${id}/`,
        headers: {
          common: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          }
        },
      })
        .then((response) => {
          const result = response.data;
          ctx.commit("updateUser", result);
        })
        .catch((error) => {
          if (error && error.request && error.request.status !== 401) {
            ctx.dispatch("fetchAlerts", {
              alerts: "Произошла ошибка",
              type: "error",
            });
          }
        });
    },
    async changeUserInfo(ctx, userData) {
      const fd = new FormData();
      let dataObj = {}
      for (let data in userData.fields) {
        dataObj[data] = userData.fields[data]
        if (userData.fields[data].size) {
          fd.append(data, userData.fields[data])
          dataObj = fd
        }
      }
      return this.$axios({
        method: "PUT",
        url: `/api/user/${userData.id}/`,
        data: dataObj,
        headers: {
          common: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          }
        },
      })
        .then(async (response) => {
          await ctx.dispatch("getUserInfo", userData.id);
          if (userData.alert) {
            ctx.dispatch("fetchAlerts", {
              alerts: userData.alert,
              type: "notify",
            });
          }
          return true;
        })
        .catch((error) => {
          ctx.dispatch("fetchAlerts", {
            alerts: "Произошла ошибка",
            type: "error",
          });
          return false;
        });
    },
    async changeUserPass(ctx, userData) {
      const fd = new FormData();
      fd.append("user_id", userData.id);
      fd.append("new_password", userData.password);
      return this.$axios({
        method: "POST",
        url: `/api/user/${userData.id}/password/`,
        data: fd,
        headers: {
          common: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          }
        },
      })
        .then(async (response) => {
          ctx.dispatch("fetchAlerts", {
            alerts: "Пароль успешно изменен",
            type: "notify",
          });
          return true;
        })
        .catch((error) => {
          let response = JSON.parse(error.request.response);
          if (response && response.non_field_errors) {
            ctx.commit("updateInputError", response.non_field_errors[0]);
          } else {
            ctx.dispatch("fetchAlerts", {
              alerts: "Произошла ошибка",
              type: "error",
            });
          }
          return false;
        });
    },
    fetchIframeAccess(ctx, id) {
      // ctx.commit("updateIframeAccess", {});
      return this.$axios({
        method: "GET",
        url: `/api/event/${id}/room-access/`,
      })
        .then((response) => {
          const result = response.data;
          let obj = {}
          obj[`event_${id}`] = { ...result }
          ctx.commit("updateIframeAccess", { ...obj });
          return true;
        })
        .catch((error) => {
          return false;
        });
    },
    deleteUserAvatar(ctx, id) {
      return this.$axios({
        method: "DELETE",
        url: `/api/user/${id}/photo`,
        headers: {
          common: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          }
        },
      })
        .then((response) => {
          ctx.dispatch("getUserInfo", id);
          return true;
        })
        .catch((error) => {
          if (error.request.status !== 401) {
            ctx.dispatch("fetchAlerts", {
              alerts: "Произошла ошибка",
              type: "error",
            });
          }
          return false;
        });
    },
    checkTutorial(ctx, obj) {
      return this.$axios({
        method: "POST",
        url: `/api/user/${obj.id}/tutorial/`,
        data: { "code": [...obj.flags] },
        headers: {
          common: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          }
        },
      })
        .then((response) => {
          ctx.dispatch("getUserInfo", id);
          return true;
        })
        .catch((error) => {
          return false;
        });
    },
    getFavoritesList(ctx, id) {
      return this.$axios({
        method: "GET",
        url: `/api/user/${id}/favorites/`,
      })
        .then((response) => {
          const result = response.data;
          ctx.commit("updateFavoritesList", result);
          return true;
        })
        .catch((error) => {
          return false;
        });
    },
    getWatchLater(ctx, id) {
      return this.$axios({
        method: "GET",
        url: `/api/user/${id}/watch-later/`,
      })
        .then((response) => {
          const result = response.data;
          ctx.commit("updateWatchLater", result);
          return true;
        })
        .catch((error) => {
          return false;
        });
    },
    getRecommendations(ctx, id) {
      return this.$axios({
        method: "GET",
        url: `/api/user/${id}/recommendations/`,
      })
        .then((response) => {
          const result = response.data;
          ctx.commit("updateRecommendations", result);
          return true;
        })
        .catch((error) => {
          return false;
        });
    },
    getRecommendEvents(ctx, id) {
      return this.$axios({
        method: "GET",
        url: `/api/user/${id}/events/`,
      })
        .then((response) => {
          const result = response.data;
          ctx.commit("updateRecommendEvents", result);
          return true;
        })
        .catch((error) => {
          return false;
        });
    },
    getHistory(ctx, id) {
      return this.$axios({
        method: "GET",
        url: `/api/user/${id}/history/`,
      })
        .then((response) => {
          const result = response.data;
          ctx.commit("updateHistory", result);
          return true;
        })
        .catch((error) => {
          return false;
        });
    },
    getNotify(ctx, id) {
      return this.$axios({
        method: "GET",
        url: `/api/user/${id}/notifications/`,
      })
        .then((response) => {
          const result = response.data;
          ctx.commit("updateNotify", result);
          return true;
        })
        .catch((error) => {
          return false;
        });
    },
    readNotify(ctx, { user_id, notify_ids }) {
      return this.$axios({
        method: "POST",
        url: `/api/user/${user_id}/notifications/`,
        data: {
          id: notify_ids
        }
      })
        .then((response) => {
          const result = response.data;
          ctx.dispatch("getNotify", user_id);
          return true;
        })
        .catch((error) => {
          return false;
        });
    },
    deleteNotify(ctx, { user_id, notify_ids }) {
      return this.$axios({
        method: "DELETE",
        url: `/api/user/${user_id}/notifications/`,
        data: {
          id: notify_ids
        }
      })
        .then((response) => {
          const result = response.data;
          ctx.dispatch("getNotify", user_id);
          return true;
        })
        .catch((error) => {
          return false;
        });
    },
    fetchSpecialitiesList(ctx) {
      return this.$axios({
        method: 'GET',
        url: 'api/sso/specialities/',
        headers: {
          common: {
            Authorization: null,
          }
        },
      })
        .then((response) => {

          if (response.data) {
            ctx.commit('updateSpecialitiesList', response.data)
          }
          return true
        })
        .catch((error) => {

          return false
        });
    },
    fetchSpecialityDetail(ctx, id) {
      return this.$axios({
        method: 'GET',
        url: `api/sso/specialities/${id}/`,
        headers: {
          common: {
            Authorization: null,
          }
        },
      })
        .then((response) => {
          if (response.data) {
            ctx.commit('updateSpecialityDetail', response.data)
          }
          return true
        })
        .catch((error) => {
          return false
        });
    }
  },
};
